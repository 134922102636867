
import {computed, defineComponent, reactive, ref} from "vue";
import * as Yup from "yup";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {Field} from "vee-validate";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {ClientSelect, HandleState, ProductSelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import {useStore} from "vuex";
import PolicyService from "@/core/services/PolicyService";
import router from "@/router";
import {IPolicy} from "@/core/entity/IPolicy";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {POLICY_STATUS} from "@/core/config/Constant";

export default defineComponent({
  name: "PolicyForm",
  components: {BaseRadio, DateTimeSelect, BaseSelect, BaseForm, FormErrorMessage, Field},
  props: {
    policyId: {required: true, type: String},
    policy: {required: true, type: Object}
  },
  setup(props) {
    const submitting = ref(false);

    const dataImport = ref(false);

    const store = useStore();
    const state = reactive({
      model: {
        number: props.policy?.number,
        status: props.policy?.status ? props.policy?.status?.code : 'TEMPORARY',
        certificate: props.policy.certificate,
        clientId: props.policy.clientId,
        productId: props.policy.product ? props.policy.product.id : '',
        effectiveDt: props.policy.effectiveDt ? props.policy.effectiveDt : new Date(),
        terminationDt: props.policy.terminationDt ? props.policy.terminationDt : '',
        issueDt: props.policy.issueDt ? props.policy.issueDt : '',
        firstName: props.policy.insureds ? props.policy.insureds[0].person.firstName : '',
        insuredId: props.policy.insureds ? props.policy.insureds[0].id : '',
        lastName: props.policy.insureds ? props.policy.insureds[0].person.lastName : '',
        dob: props.policy.insureds ? props.policy.insureds[0].person.dob : '',
        gender: props.policy.insureds ? props.policy.insureds[0]?.person?.gender : 'F',
        policyBenefits: props.policy.policyBenefits ? props.policy.policyBenefits : {
          deductible: 0,
          coinsurance: 0,
          maximum: 0
        }
      }
    })

    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const validate = Yup.object().shape({
      number: Yup.string().required().label("Number"),
      clientId: Yup.string().required().label("Client"),
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
    });

    const onSubmitForm = () => {
      submitting.value = true
      if (props.policyId) {
        PolicyService.update<IPolicy>(props.policyId, state.model).then(res => {
          router.push({
            name: "policyOverview",
            params: {id: res.id}
          })
        }).finally(() => {
          submitting.value = false
        })
      } else {
        PolicyService.create<IPolicy>(state.model).then(res => {
          router.push({
            name: "policyOverview",
            params: {id: res.id}
          })
        }).finally(() => {
          submitting.value = false
        })
      }
    }
    const benefitSubmit = () => {

      submitting.value = true
      PolicyService.updateBenefits(props.policyId, state.model).then(res => {
        router.push({
          name: "policyOverview",
          params: {id: res.id}
        })
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      dataImport,
      onSubmitForm,
      benefitSubmit,
      ...ProductSelect(),
      ...ClientSelect(),
      countries,
      submitting,
      validate,
      states,
      state,
      ...HandleState(),
      POLICY_STATUS,
    }
  },
  mounted() {
    if (this.policy.client) {
      this.onClientSelect(this.policy?.client?.id)
    }
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    onClientSelect(clientId) {
      const client = this.clients.find(item => item.id === clientId)
      if (client) {
        this.dataImport = client.dataImport;
        // if(!this.dataImport) {
        //   this.state.model.status = 'ACTIVE'
        // }else {
        //   this.state.model.status = 'TEMPORARY'
        // }
      }
      this.productFilter.companyId = clientId;
      this.loadProducts();
    }
  }
})
